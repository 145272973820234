import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IControlPageProps } from './interfaces';
import { AppContext } from '@providers/AppProvider';
import { IAppContext } from '@providers/AppProvider/interfaces';
import * as QueryString from 'query-string';
import { FormPage } from './Form';
import { FieldControl } from '@controls/FieldControl';
import { FormControlType } from '@controls/native/Form/interfaces/enums';
import { IEntityFormPageContext } from '@app/interfaces/entityformpagecontext';
import { sanitizeGuid, setPageType, usePreviousValue } from '@app/Functions';
import { Authentication, requireAuthentication } from '@app/classes/Authentication';
import { IInlineDialogPageContext } from '@src/app/interfaces/inlinedialogpagecontext';
import FormDialog from '@src/components/dialogs/FormDialog';
import { HistoryContext, history } from '@src/providers/HistoryProvider/HistoryProvider';
import { DatasetControlRenderer } from '@src/components/controls/DatasetControl/DatasetControlRenderer';

enum PageType {
    Form = 'form',
    View = 'view',
    Dialog = 'dialog'
}

export const ControlPage: React.FC<IControlPageProps> = (props) => {
    const [queryData, setQueryData] = useState<IEntityFormPageContext | Xrm.EntityListPageContext | IInlineDialogPageContext>(null);
    const previousQueryData = usePreviousValue(queryData);
    const [controlName, setControlName] = useState<string>(() => {
        setPageType(props.match.params.controlName);
        return props.match.params.controlName;
    });
    const appContext: IAppContext = useContext(AppContext);
    const historyContext = useContext(HistoryContext);
    const [shouldReload, setShouldReload] = useState<boolean>(false);
    const extraQsRef = useRef<QueryString.ParsedQuery<string>>(null);
    const query = new URLSearchParams(props.location.search);

    useEffect(() => {
        if (!Authentication.isAuthenticated()) {
            requireAuthentication();
            return;
        }
        appContext.SetLoading(false);
        const _controlName = props.match.params.controlName;
        setControlName(_controlName);
        setPageType(_controlName);
        const data = Xrm.Utility.getPageContext().input as (Xrm.EntityFormPageContext | Xrm.EntityListPageContext | IInlineDialogPageContext);
        if (_controlName === PageType.Form || _controlName === PageType.View || _controlName === PageType.Dialog) {
            appContext.closeAllFormDialogs();
            switch (data.pageType) {
                case "entityrecord":
                    setQueryData({
                        entityName: data?.entityName,
                        pageType: data?.pageType,
                        formId: data?.formId,
                        entityId: sanitizeGuid(data?.entityId),
                        data: (data as IEntityFormPageContext)?.data
                    });
                    break;
                case "entitylist":
                    setQueryData({
                        viewId: data?.viewId,
                        viewType: data?.viewType,
                        entityName: data?.entityName,
                        pageType: data?.pageType
                    });
                    break;
                case "inlinedialog": {
                    setQueryData({
                        pageType: data.pageType,
                        uniqueName: data?.uniqueName
                    });
                    break;
                }
            }

            extraQsRef.current = (data as IEntityFormPageContext)?.data !== null ? (data as IEntityFormPageContext).data : null;
        }
    }, [historyContext.currentPage]);

    useEffect(() => {
        if (appContext.shouldRefreshMain) {
            appContext.setShouldRefreshMain(false);
        }
    }, [appContext.shouldRefreshMain]);

    // We need to unmount and mount the Form component to be able to execute onload scripts
    // In future this will need a custom comparison logic with previous values to decide when to re-render.
    // Moved to useMemo because it runs before the component renders => this prevents rendering DatasetControl too early which resulted in double initialization
    useMemo(() => {
        //fixes an issue where calling setShouldReload while coming from non-control page (like SearchPage) would cause the page not being rendered
        //TODO: Search Page should not be it's own Route, but should come through Control page like other pages (Form, View, Dialog) so we have the navigation stuff in one place and we can avoid this BS (Search Page even has some copy pasted code from Control instead of it being rendered via it...)
        if (!previousQueryData) {
            return;
        }
        if (previousQueryData?.pageType === "entitylist" && queryData?.pageType === "entitylist" &&
            previousQueryData?.viewId !== queryData?.viewId &&
            previousQueryData.entityName === queryData.entityName &&
            previousQueryData?.viewType === queryData?.viewType
        ) {
            // Skip reload to prevent flickering of view when only viewId has changed
        }
        else {
            setShouldReload(true);
        }
    }, [queryData]);

    useEffect(() => {
        if (shouldReload) {
            setShouldReload(false);
        }
    }, [shouldReload]);

    if (!appContext.shouldRefreshMain && !shouldReload && appContext.isNavbarLoaded) {
        if (controlName === PageType.Form && !appContext.isLoading && queryData?.pageType === 'entityrecord' && queryData?.entityName) {
            return <FormPage
                bindings={{
                    "entityName": {
                        isStatic: true,
                        value: queryData.entityName
                    },
                    "entityId": {
                        isStatic: true,
                        value: queryData.entityId,
                    },
                    "formId": {
                        isStatic: true,
                        value: queryData.formId,
                    },
                    "extraQs": {
                        isStatic: true,
                        value: QueryString.stringify(extraQsRef.current, { skipNull: false, skipEmptyString: false }),
                    },
                    "isNavbarLoaded": {
                        isStatic: true,
                        value: appContext.isNavbarLoaded.toString()
                    },
                    //optional props, could be used to conditionally render ribbon and form picker on form page
                    "EnableFormPicker": {
                        isStatic: true,
                        value: "true"
                    },
                    "EnableFormPageRibbon": {
                        isStatic: true,
                        value: "true"
                    }
                }}
            />;
        }
        //TODO: Support for individual control pages.
        else if (controlName === PageType.View && queryData?.pageType === 'entitylist' && queryData?.entityName) {
            return <DatasetControlRenderer
                type={FormControlType.DataSet}
                name="TALXIS.PCF.Portal.View"
                id=""
                bindings={{
                    ViewId: {
                        isStatic: true,
                        value: queryData.viewId
                    },
                    EnableNavigation: {
                        isStatic: true,
                        value: "true"
                    },
                    TargetEntityType: {
                        isStatic: true,
                        value: queryData.entityName
                    },
                    SelectableRows: {
                        isStatic: true,
                        value: "multiple"
                    },
                    IsViewPage: {
                        isStatic: true,
                        value: 'true'
                    },
                    DisplayCommandBar: {
                        isStatic: true,
                        value: 'true'
                    },
                    RecordsPerPage: {
                        isStatic: true,
                        //@ts-ignore - pagingLimit is part of userSettings in Power Apps, but is not documented
                        value: window.Xrm.Utility.getGlobalContext().userSettings.pagingLimit
                    },
                    EnableEditColumns: {
                        isStatic: true,
                        value: query.get('vieweditcolumns') ?? "true"
                    },
                    EnableQuickFind: {
                        isStatic: true,
                        value: query.get('viewsearch') ?? "true"
                    },
                    EnableSorting: {
                        isStatic: true,
                        value: query.get('viewsorting') ?? "true"
                    },
                    EnableFiltering: {
                        isStatic: true,
                        value: query.get('viewfilters') ?? "true"
                    },
                    EnableViewPicker: {
                        isStatic: true,
                        value: query.get('viewswitching') ?? "true"
                    },
                    Height: {
                        isStatic: true,
                        value: '100%'
                    },
                    EnableUserQueries: {
                        isStatic: true,
                        value: query.get('userqueries') ?? "true"
                    }
                }}
            />;
        }
        else if (!appContext.isLoading && controlName === PageType.Dialog && queryData?.pageType === 'inlinedialog') {
            return <FormDialog formName={queryData.uniqueName} position={-100} />;
        }
        else if (!appContext.isLoading && controlName !== PageType.Form && controlName !== PageType.View) {
            return <FieldControl name={controlName}
                // TODO: Assemble bindings https://dev.azure.com/thenetworg/INT0015/_workitems/edit/22993/
                bindings={{}}
                childeventlisteners={[]}
                disableLoading
                id={controlName}
                classId=""
                datafieldname={null}
                disabled={false}
                type={FormControlType.Field}
                visible={true}
                isUnbound={true}
                isRequired={false}
                definition={null}
            />;
        }
        else if (queryData) {
            history.replace('/');
        }
    }
    return null;
};