import { Dataset, IDataset } from "@talxis/client-libraries/dist/utils/dataset";
import { IXrmGridRelationship, XrmGridType, XrmRelationshipType } from "./interfaces";
import { IViewDefinition } from "../native/View/interfaces/viewdefinition";
import { EntityDefinition as IEntityDefinition } from '@src/app/interfaces/entitydefinition';
import { Attribute, DataTypes, FetchXml, FetchXmlBuilder, FetchXmlDataProvider, IFetchXmlDataProviderColumn, ILayout, Operators, Sanitizer, Type } from "@talxis/client-libraries";
import { EntityDefinition } from "@src/app/classes/definitions/EntityDefinition";
import { ViewDefinition } from "@src/app/classes/definitions/ViewDefinition";
import { DomParser } from "@src/app/Constants";
import { sanitizeGuid } from "@src/app/Functions";
import { TransactionCurrencyDefinition } from "@src/app/classes/definitions/TransactionCurrencyDefinition";
import { DatasetControl } from "./DatasetControl";
import { HistoryManager } from "@src/providers/HistoryProvider/HistoryManager";

export class Grid extends Dataset<FetchXmlDataProvider> {
    private _relationship: IXrmGridRelationship;
    private _initialized: boolean = false;
    private _entityDefinition: IEntityDefinition;
    private _viewId: string;
    private _quickFindFilterExpression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression;
    private _viewDefinition: IViewDefinition
    private _initialFetchXml: string;
    private _control: DatasetControl
    protected _dataProvider: FetchXmlDataProvider = null;

    constructor(entityName: string) {
        const provider = new FetchXmlDataProvider("", {
            doNotFetchLayout: true,
            doNotFetchTitle: true,
        });
        provider.setEntityName(entityName);
        super(provider);
        this._dataProvider = provider;
    }

    public async init(control: DatasetControl): Promise<boolean> {
        this._control = control;
        if (this.isRelatedSubgridOnNewRecord) {
            return false;
        }
        const entityName = this.bindings?.TargetEntityType?.value;
        [this._entityDefinition, this._viewId, this._quickFindFilterExpression] = await Promise.all([
            EntityDefinition.getAsync(entityName),
            this.bindings.ViewId?.value || this.bindings?.[this.datasetBindingName]?.ViewId || this._getInitialViewId(entityName),
            this._getQuickFindFilterExpression(entityName)
        ]);
        [this._viewDefinition, this._relationship] = await Promise.all([
            ViewDefinition.getAsync(this._viewId),
            this._getRelationship(entityName, this._entityDefinition)
        ]);
        let baseFetchXml = this._control.state.values?.current?.dataSource ?? "";
        let title;
        [title, baseFetchXml] = await Promise.all([
            //the localized title should ideally be in the current view definition, it currently always gets czech translations
            (await ViewDefinition.getViewNamesAndIds(entityName, { includeUserQueries: this.userQueriesEnabled })).find(x => x.queryid === sanitizeGuid(this._viewId))?.name ?? this._viewDefinition.name,
            (async () => {
                //get our initialFetchXml which will always be the same and will not be affected by any state
                this._initialFetchXml = await this._getInitialFetchXml(entityName, this._entityDefinition, this._viewDefinition, this._quickFindFilterExpression, this._relationship);
                //use either stored fetchXml from state or the initial one
                return baseFetchXml || this._initialFetchXml;
            })()
        ]);
        this.setDataSource(baseFetchXml);
        this.setCurrencies(TransactionCurrencyDefinition.getCurrencies());
        this.setTitle(title);
        //@ts-ignore - we need to override this with quick find, we could possible introduce official override in the future if needed
        this._dataProvider._getSearchQueryFilter = () => this._getQuickFindFilter();
        this._control.setDefaultStateValues(this.columns, [], [], this._initialFetchXml);
        if (!this.formContext) {
            //todo: tyopings
            HistoryManager.getCurrentPage().setDataset(this as any);
        }
        this._initialized = true;
        return true;
    }

    public get isInitialized() {
        return this._initialized;
    }

    public get isRelatedSubgridOnNewRecord() {
        if (this.formContext && !this.formContext.entityId && this.bindings.RelationshipName) {
            return true;
        }
        return false;
    }

    public get bindings() {
        return this._control.bindings;
    }

    public setColumns(columns: IFetchXmlDataProviderColumn[]): void {
        this._dataProvider.setLayoutColumns(columns);
        this._dataProvider.setColumns(columns);
    }

    public setDataSource(dataSource: string): void {
        super.setDataSource(dataSource);
        this._dataProvider.setLayoutColumns(this._layoutToColumns(JSON.parse(this._viewDefinition.layoutjson)));
    }

    public get datasetBindingName() {
        return this._control.datasetBindingName;
    }

    public get isHomePageGrid() {
        return this.gridType === XrmGridType.HomepageGrid;
    }

    public get relationship() {
        return this._relationship;
    }

    public get gridType() {
        return this._control.type;
    }

    public get initialFetchXml() {
        return this._initialFetchXml;
    }

    public get cdsOptions() {
        return this._control.cdsOptions;
    }

    public get formContext() {
        return this._control.formContext;
    }

    public get ribbon() {
        return this._control.ribbon;
    }

    public get quickFind() {
        return this._control.quickFind;
    }

    public get gridContext() {
        return this._control.gridContext;
    }

    public get userQueriesEnabled() {
        return this._control.bindings.EnableUserQueries?.value === 'true';
    }

    public getControl() {
        return this._control;
    }

    public get viewSelector() {
        return this._control.viewSelector;
    }

    public get quickFindColumnNames() {
        return this._quickFindFilterExpression.conditions.map(x => x.attributeName);
    }

    public async retrieveRecordCommand(recordIds: string[] = [], specificCommands: string[], filterByPriority: boolean, useNestedFormat: boolean, refreshAllRules: boolean): Promise<Ribbon.Command[]> {
        if (filterByPriority || useNestedFormat) {
            console.warn('filterByPriority and useNestedFormat parameters are currently not supported in Portal.');
        }
        let commands = await this.ribbon.getCommands(specificCommands, recordIds);
        if (recordIds.length === 0) {
            commands = commands.filter(command => command.shouldBeVisible);
        }
        return commands;
    }

    //we need to override this with portal implementation to override the native FetchXml provider dataset item open
    //needs to be here until FetchXmlProvider has support for Mscrm.OpenRecordItem => requires ribbons to be lifted from Portal
    public async openDatasetItem(entityReference: ComponentFramework.EntityReference) {
        const openDatasetItemEvents = this._eventListeners.onDatasetItemOpened ?? [];
        if (openDatasetItemEvents.length > 0) {
            openDatasetItemEvents.map(event => event(entityReference));
        }
        else {
            entityReference = Sanitizer.Lookup.getEntityReference(entityReference);
            const openForm = () => {
                window.Xrm.Navigation.openForm({
                    entityName: entityReference.etn,
                    entityId: entityReference.id.guid,
                });
            };
            //opening other entity lookup
            if (this.getTargetEntityType() !== entityReference.etn) {
                openForm();
                return;
            }
            (async () => {
                const openRecordCommand = await this.ribbon.getCommand('Mscrm.OpenRecordItem');
                if (openRecordCommand) {
                    openRecordCommand.execute();
                    return;
                }
                openForm();
            })();
        }
    }

    private async _getInitialViewId(entityName: string) {
        const availableViews = await ViewDefinition.getViewNamesAndIds(entityName, { viewIds: this.bindings?.ViewIds?.value.split(','), includeUserQueries: this.userQueriesEnabled });
        if (availableViews.length > 0) {
            return availableViews[0].queryid;
        }
        throw new Error(`Not view found for entity ${entityName}!`);
    }

    private async _getQuickFindFilterExpression(entityName: string): Promise<ComponentFramework.PropertyHelper.DataSetApi.FilterExpression> {
        const quickFindViewDefinition = await ViewDefinition.getQuickFindViewAsync(entityName);
        const parsedDefinition = DomParser.parseFromString(quickFindViewDefinition.fetchxml, "text/xml");
        const conditions = await Promise.all([...parsedDefinition.getElementsByTagName("condition")].filter(x => x.getAttribute("value") === "{0}").map(async x => {
            let attributeName = x.getAttribute('attribute');
            const linkedEntityAlias = x.getAttribute('entityname');
            const attributeMetadata = await new FetchXml(quickFindViewDefinition.fetchxml).attribute(linkedEntityAlias ? `${linkedEntityAlias}.${attributeName}` : attributeName).getMetadata();

            switch (Attribute.GetDataTypeFromMetadata(attributeMetadata)) {
                case DataTypes.LookupSimple:
                case DataTypes.LookupOwner:
                case DataTypes.LookupCustomer: {
                    attributeName += "name";
                }
            }
            return {
                attributeName: attributeName,
                conditionOperator: Operators.Like.Value,
                value: null,
                entityAliasName: x.getAttribute('entityname')
            };
        }));

        return {
            filterOperator: 1,
            conditions: conditions
        };
    }

    private _getQuickFindFilter(): ComponentFramework.PropertyHelper.DataSetApi.FilterExpression | null {
        if (!this.getSearchQuery()) {
            return null;
        }
        return {
            ...this._quickFindFilterExpression,
            conditions: this._quickFindFilterExpression.conditions.map(x => {
                return {
                    ...x,
                    value: this.getSearchQuery().startsWith('*') ?
                        `${this.getSearchQuery().replace('*', '%')}%` :
                        `${this.getSearchQuery()}%`,
                };
            })
        };
    }

    private _getRelationship(entityName: string, entityDefinition: IEntityDefinition): IXrmGridRelationship | null {
        if (!this.bindings.RelationshipName?.value) {
            return null;
        }
        let relationship = entityDefinition.ManyToOneRelationships.find(x => x.SchemaName === this.bindings.RelationshipName.value);
        if (relationship) {
            return {
                attributeName: relationship.ReferencingAttribute,
                entityName: entityName,
                name: relationship.SchemaName,
                recordId: this.formContext.entityId,
                relationshipType: XrmRelationshipType.OneToMany,
                metadata: {
                    oneToMany: relationship
                }
            };
        }
        else if (entityDefinition.ManyToManyRelationships.find(x => x.SchemaName === this.bindings.RelationshipName.value)) {
            const many2Many = entityDefinition.ManyToManyRelationships.find(x => x.SchemaName === this.bindings.RelationshipName.value);
            // Correctly locate the relationship direction - either parent > entity1 > child or parent > entity2 > child
            const currentEntityIntersectAttribute = many2Many.Entity1LogicalName === entityName ? many2Many.Entity1IntersectAttribute : many2Many.Entity2IntersectAttribute;
            const relatedEntityIntersectAttribute = many2Many.Entity1LogicalName === entityName ? many2Many.Entity2IntersectAttribute : many2Many.Entity1IntersectAttribute;

            return {
                attributeName: many2Many.Entity2IntersectAttribute,
                entityName: entityName,
                name: many2Many.SchemaName,
                recordId: this.formContext.entityId,
                relationshipType: XrmRelationshipType.ManyToMany,
                metadata: {
                    many2Many: {
                        ...many2Many,
                        CurrentEntityIntersectAttribute: currentEntityIntersectAttribute,
                        RelatedEntityIntersectAttribute: relatedEntityIntersectAttribute
                    }
                }
            };
        }
        throw new Error(`Unable to find relationship ${this.bindings.RelationshipName.value} in entity definitions!`);
    }

    //needs to be run after relationship and quickfind filters get resolved
    private async _getInitialFetchXml(entityName: string, entityDefinition: IEntityDefinition, viewDefinition: IViewDefinition, quickFindFilterExpression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression, relationship?: IXrmGridRelationship): Promise<string> {
        const fetchXml = new FetchXml(viewDefinition.fetchxml).builder;
        // Distinct aliases https://codeburst.io/javascript-array-distinct-5edc93501dc4
        const aliases = [...new Set(quickFindFilterExpression.conditions.map(x => x.entityAliasName).filter(x => x !== null && x !== undefined))];
        const quickFindViewDefinition = await ViewDefinition.getQuickFindViewAsync(entityName);
        const parsedDefinition = DomParser.parseFromString(quickFindViewDefinition.fetchxml, "text/xml");
        const linkedEntities = [...parsedDefinition.getElementsByTagName("link-entity")];
        aliases.map(alias => {
            const link = linkedEntities.find(x => x.getAttribute("alias") === alias);
            fetchXml.entity.addLinkEntity(new FetchXmlBuilder.linkEntity(
                link.getAttribute('name'),
                link.getAttribute('from'),
                link.getAttribute('to'),
                link.getAttribute('link-type'),
                alias
            ));
        });
        // If FetchXML doesn't contain the PrimaryId, it needs to be added so that we can create links etc.
        fetchXml.entity.attributes = [...fetchXml.entity.attributes.filter(attr => attr.name !== entityDefinition.PrimaryIdAttribute), new FetchXmlBuilder.attribute(entityDefinition.PrimaryIdAttribute)];
        if (!relationship) {
            return fetchXml.toXml();
        }
        if (relationship.relationshipType === XrmRelationshipType.OneToMany) {
            const oneToMany = relationship.metadata.oneToMany;
            fetchXml.entity.addLinkEntity(new FetchXmlBuilder.linkEntity(
                oneToMany.ReferencedEntity,
                oneToMany.ReferencedAttribute,
                oneToMany.ReferencingAttribute,
                "inner",
                "__relatedEntityFilter"
            ));
            fetchXml.entity.linkEntities.find(x => x.alias === '__relatedEntityFilter').addFilter(
                new FetchXmlBuilder.filter(
                    Type.GetNameFromValue(0),
                    [new FetchXmlBuilder.condition(
                        oneToMany.ReferencedAttribute,
                        Operators.GetNameFromValue(0),
                        [new FetchXmlBuilder.value(this.formContext.entityId)]
                    )]
                )
            );
        }
        else {
            const many2Many = this.relationship.metadata.many2Many;
            const isSelfReferenced = many2Many.Entity1LogicalName === many2Many.Entity2LogicalName;
            const intersectTableLinkedEntity = new FetchXmlBuilder.linkEntity(
                many2Many.IntersectEntityName,
                many2Many.CurrentEntityIntersectAttribute,
                many2Many.CurrentEntityIntersectAttribute,
                "inner",
                '__relatedEntityFilter',
            );
            const linkedEntity = new FetchXmlBuilder.linkEntity(
                entityName === many2Many.Entity2LogicalName ? many2Many.Entity1LogicalName : many2Many.Entity2LogicalName,
                isSelfReferenced ? entityDefinition.PrimaryIdAttribute : entityName === many2Many.Entity2LogicalName ? many2Many.Entity1IntersectAttribute : many2Many.Entity2IntersectAttribute,
                entityName === many2Many.Entity2LogicalName ? many2Many.Entity1IntersectAttribute : many2Many.Entity2IntersectAttribute,
                "inner"
            );
            linkedEntity.addFilter(
                new FetchXmlBuilder.filter(
                    Type.GetNameFromValue(0),
                    [new FetchXmlBuilder.condition(
                        many2Many.RelatedEntityIntersectAttribute,
                        Operators.GetNameFromValue(0),
                        [new FetchXmlBuilder.value(this.formContext.entityId)]
                    )]
                )
            );
            intersectTableLinkedEntity.addLinkEntity(linkedEntity);
            fetchXml.entity.addLinkEntity(intersectTableLinkedEntity);
        }
        return fetchXml.toXml();
    }

    private _layoutToColumns(layout: ILayout): Partial<ComponentFramework.PropertyHelper.DataSetApi.Column> & { name: string }[] {
        return layout.Rows[0].Cells.map(x => {
            return {
                name: x.Name,
                isHidden: x.IsHidden,
                visualSizeFactor: x.Width,
            };
        });
    }
}