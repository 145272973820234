import React, { useRef, useEffect, useState } from 'react';
import { Panel, IPanelProps, PrimaryButton, PanelType, DefaultButton, MessageBar } from '@fluentui/react';
import { Grid } from '../Grid';
import { useQueryBuilder } from './useQueryBuilder';
import styles from './QueryBuilder.module.css';
import { CommandBar } from '@talxis/react-components';
import { ThemeDefinition } from '@src/app/classes/definitions/ThemeDefinition';

interface IQueryBuilderPanel extends IPanelProps {
    grid: Grid;
}

export const QueryBuilderPanel: React.FC<IQueryBuilderPanel> = (props) => {
    const queryBuilderContainerRef = useRef<HTMLDivElement>(null);
    const userFilterQueryBuilderContainerRef = useRef<HTMLDivElement>(null);
    const [isInitalized, init, save, isDirty, commandBarProps] = useQueryBuilder(props.grid, false);
    const [isUserFilterQueryBuilderInititalized, initUserFilterQueryBuilder] = useQueryBuilder(props.grid, true);
    const [panelOpened, setIsPanelOpened] = useState<boolean>(false);
    useEffect(() => {
        setIsPanelOpened(true);
    }, []);
    return (
        <Panel
            isOpen={panelOpened}
            type={PanelType.custom}
            customWidth="600px"
            headerText={`${window.TALXIS.Portal.Translations.getLocalizedString('@pages/Control/View/EditFilters')}: ${props.grid.getTitle()}`}
            className={styles.root}
            isFooterAtBottom
            onOuterClick={() => { }}
            isLightDismiss={false}
            isBlocking={true}
            onOpened={() => {
                init(queryBuilderContainerRef.current);
                if (props.grid.filtering.getFilter()?.conditions?.length > 0) {
                    initUserFilterQueryBuilder(userFilterQueryBuilderContainerRef.current);
                }
            }}
            onRenderFooterContent={() => {
                return (
                    <div className={styles.footer}>
                        <PrimaryButton
                            text={window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/Save")}
                            disabled={!isDirty}
                            onClick={() => {
                                save();
                                props.onDismiss();
                            }} />
                        <DefaultButton
                            text={window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/Cancel")}
                            onClick={() => props.onDismiss()} />
                    </div>
                );
            }}
            layerProps={{
                styles: {
                    root: {
                        //reason for this zindex : prevents layer covering querybuilder contextual menus
                        zIndex: 999999
                    }
                }
            }}
            {...props}
        >
            <CommandBar contextualMenuTheme={ThemeDefinition.get().main} {...commandBarProps} />
            <div ref={queryBuilderContainerRef} />
            {props.grid.filtering.getFilter()?.conditions?.length > 0 &&
                <div>
                    {isUserFilterQueryBuilderInititalized &&
                        <MessageBar>These filters can be changed directly on the grid.</MessageBar>
                    }
                    <div ref={userFilterQueryBuilderContainerRef} />
                </div>
            }
        </Panel>
    );
};