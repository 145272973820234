import React, { useState } from 'react';
import { mergeStyles, PanelType, ThemeProvider } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react';
import { CommandBar } from '@talxis/react-components';
import { AppPanel } from './components/AppPanel';
import { useEffect } from 'react';
import { AppModule } from '@configuration/AppModule';
import styles from './Navbar.module.css';
import { useMemo } from 'react';
import { history } from '@providers/HistoryProvider/HistoryProvider';
import { ThemeDefinition } from '@definitions/ThemeDefinition';
import { AppSwitcherDialog } from '../../../../components/dialogs/AppSwitcherDialog';
import { SitemapDefinition } from '@src/app/classes/definitions/SitemapDefinition';
import { RibbonController } from '@src/components/navigation/ribbon/RibbonController';
import { AppRibbon } from '@src/app/classes/models/Ribbon/AppRibbon';
import { TextField } from '@talxis/base-controls';
import { useLocation } from 'react-router-dom';
import { Context } from '@src/ComponentFramework/PropertyClasses/Context';

interface INavbarProps {
    navbarLoaded: boolean;
    ribbon: AppRibbon;
    verticalNavigationEnabled?: boolean;
    verticalNavigationVisible?: boolean;
    logoSrc?: string;
    envName?: string;
    globalSearch: boolean;
    onToggleVerticalNavigation?: (visible: boolean) => void;
}

export const Navbar: React.FC<INavbarProps> = (props) => {
    const theme = ThemeDefinition.get().navbar;
    const location = useLocation();
    const previousLocationRef = React.useRef<typeof location>(null);
    const [isAppPanelOpen, setIsAppPanelOpen] = useState<boolean>(false);
    const [showAppPanelBtn, setShowAppPanelBtn] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [appSwitcherDialogVisible, setAppSwitcherDialogVisible] = useState<boolean>(false);
    const [showFloatingSearchBar, setShowFloatingSearchBar] = useState<boolean>(false);
    const additionalCommands: ICommandBarItemProps[] = [];

    if (props.globalSearch) {
        additionalCommands.push({
            className: props.envName ? styles.globalSearchIcon : styles.primarySearchIcon,
            key: 'globalSearchIcon',
            iconOnly: true,
            iconProps: { iconName: 'Search' },
            onClick: () => {
                setShowFloatingSearchBar(true);
            }
        } as ICommandBarItemProps);
    }

    const commandBarStyles = useMemo(() => {
        return mergeStyles({
            '.TALXIS__ribbon__icon__svg path': {
                fill: theme.palette.themePrimary
            },
            ' > div > div > .ms-CommandBar': {
                height: 48,
                '> .ms-CommandBar-primaryCommand': {
                    justifyContent: 'flex-end'
                },
                '> div > div > .TALXIS__ribbon__pcf>div>.ms-Button--commandBar': {
                    backgroundColor: 'transparent',
                    height: 48,
                    '> span .ms-Persona-primaryText, > span .ms-Button-label': {
                        color: theme.semanticColors.bodyText,
                        fontWeight: 600
                    },
                    ':hover': {
                        backgroundColor: theme.semanticColors.buttonBackgroundHovered
                    },
                    ':active': {
                        backgroundColor: theme.semanticColors.buttonBackgroundPressed
                    },
                }
            }
        });
    }, [theme]);
    const getLeftSideItems = () => {
        const items: ICommandBarItemProps[] = [];
        if (props.verticalNavigationEnabled) {
            const menuButton: ICommandBarItemProps = {
                key: 'menu',
                className: styles.menuButton,
                iconProps: {
                    iconName: 'GlobalNavButton'
                },
                onClick: () => props.onToggleVerticalNavigation(!props.verticalNavigationVisible)
            };
            items.push(menuButton);
        }
        if (showAppPanelBtn) {
            items.push({
                key: 'appSwitcher',
                className: styles.appSwitcherButton,
                iconProps: {
                    iconName: 'WaffleOffice365'
                },
                onClick: () => setIsAppPanelOpen(true)
            });
        }

        if (props.logoSrc) {
            items.push({
                key: 'logo',
                className: `TALXIS__navbar__left-side__logo ${styles.logo}`,
                onClick: (e) => {
                    e.preventDefault();
                    history.push(SitemapDefinition.getCurrentSiteMap().getDefinition().areas[0]?.groups[0]?.subAreas[0]?.url);
                },
                iconProps: {
                    imageProps: {
                        shouldFadeIn: false,
                        src: props.logoSrc
                    }
                }
            });
        }

        if (showAppPanelBtn) {
            items.push({
                key: "appModuleName",
                text: AppModule.get().name,
                className: styles.appModuleName,
                onClick: () => {
                    if (props.verticalNavigationEnabled && (window.innerWidth < 768)) {
                        setIsAppPanelOpen(true);
                    }
                    else {
                        setAppSwitcherDialogVisible(true);
                    }
                }
            });
        }

        return items;
    };
    const init = async () => {
        const appModules = await AppModule.getAllAppModules();
        if (appModules.length > 1) {
            setShowAppPanelBtn(true);
        }
    };
    useEffect(() => {
        if (!props.navbarLoaded) {
            return;
        }
        init();
    }, [props.navbarLoaded]);
    useEffect(() => {
        if (previousLocationRef.current?.pathname !== location.pathname || previousLocationRef.current?.search !== location.search) {
            const query = new URLSearchParams(location.search);
            if (location.pathname.endsWith('/search')) {
                const searchText = query.get("searchText");
                if (searchText) {
                    setSearchValue(searchText);
                }
                else {
                    setSearchValue('');
                }
            }
            else if (previousLocationRef.current?.pathname.endsWith('/search')) {
                setSearchValue('');
            }
        }
        previousLocationRef.current = location;
    }, [location]);
    const getCSSVariables = (): any => {
        if (props.verticalNavigationVisible !== undefined) {
            return {
                '--menu-button-display': !props.verticalNavigationVisible ? 'block' : 'none'
            };
        }
    };
    const onSearch = (newValue: string) => {
        if (newValue && newValue.trim()) {
            const composedUrl = `/${AppModule.get().uniquename}/search?searchText=${encodeURIComponent(newValue.trim())}`;
            history.push(composedUrl);
        }
    };

    const getGlobalSearchFieldStyles = () => {
        if (showFloatingSearchBar) {
            return mergeStyles({
                display: 'block !important',
                marginLeft: 5,
                marginRight: 5,
                '>div': {
                    width: '100% !important'
                }
            });
        }
        return '';
    };
    return (
        <nav style={getCSSVariables()} className={`TALXIS__navbar ${styles.root} ${mergeStyles({
            borderBottom: `1px solid ${theme.semanticColors.menuDivider}`
        })}`}>
            <ThemeProvider theme={theme}>
                {!showFloatingSearchBar &&
                    <>
                        <CommandBar
                            className={`TALXIS__navbar__left-side ${styles.leftSide} ${commandBarStyles}`}
                            items={[]}
                            contextualMenuTheme={ThemeDefinition.get().main}
                            farItems={getLeftSideItems()}
                        />

                        {props.envName &&
                            <div className={styles.navbarEnvName}>
                                <span className={styles.envName}>
                                    {props.envName.toUpperCase()}
                                </span>
                            </div>
                        }
                    </>
                }
                {!props.envName && props.globalSearch &&
                    <div className={`${styles.globalSearchField} ${getGlobalSearchFieldStyles()}`}>
                        <TextField context={Context.getBasicContext(ThemeDefinition.get().navbarV9)}
                            parameters={{
                                value: {
                                    raw: searchValue
                                }
                            }}
                            onOverrideComponentProps={(props) => {
                                return {
                                    ...props,
                                    placeholder: `${window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/Search/Search")}...`,
                                    onKeyUp: (e) => {
                                        if (e.key === 'Enter') {
                                            onSearch(searchValue);
                                        }
                                    },
                                    onChange: (e, newValue) => {
                                        props.onChange?.(e, newValue);
                                        setSearchValue(newValue);
                                    },
                                    suffixItems: [...props.suffixItems ?? [], {
                                        key: 'search',
                                        iconProps: {
                                            iconName: 'Search'
                                        },
                                        onClick: () => { onSearch(searchValue); }
                                    }, ...(showFloatingSearchBar ? [{
                                        key: 'showNavbar',
                                        iconProps: {
                                            iconName: 'Cancel'
                                        },
                                        onClick: () => setShowFloatingSearchBar(false)
                                    }] : [])]
                                };
                            }}
                        />
                    </div>
                }

                {props.navbarLoaded &&
                    <RibbonController
                        className={`${styles.ribbon} ${styles.rightSide} ${mergeStyles({
                            display: showFloatingSearchBar ? 'none !important' : undefined
                        })}`}
                        commandBarClassName={`${commandBarStyles} ${styles.ribbonCommandBar}`}
                        ribbon={props.ribbon}
                        additionalCommands={additionalCommands} />
                }

            </ThemeProvider>
            <AppPanel
                isOpen={isAppPanelOpen}
                type={PanelType.customNear}
                customWidth={'350px'}
                isLightDismiss
                headerText={window.TALXIS.Portal.Translations.getLocalizedString("components/dialogs/AppSwitcherDialog/Title")}
                onDismiss={() => setIsAppPanelOpen(false)} />
            {appSwitcherDialogVisible &&
                <AppSwitcherDialog appSwitcherDialogVisible={appSwitcherDialogVisible} setAppSwitcherDialogVisible={setAppSwitcherDialogVisible} />
            }
        </nav>
    );
};
