import { isDev } from "../../Functions";
import { getTheme, loadTheme, mergeStyleSets } from "@fluentui/react";
import { buildMetadataUrl, getWebResourceUrl } from "@definitions/MetadataApi";
import { DEFAULT_BODY_MAIN_BACKGROUND_COLOR, FRONTEND_VERSION } from "@src/app/Constants";
import { ThemeType } from "../utilities/ThemeDesigner";
import { Theming, ITheme } from "@talxis/react-components";
import { IFluentDesignState, ControlTheme } from '@talxis/base-controls';

export enum SitemapOrientation {
    Horizontal = 742070000,
    Vertical = 742070001
}
export enum SitemapAlignment {
    Center = 742070000,
    Left = 742070001
}

interface ISitemapParameters {
    horizontalAlignment: SitemapAlignment;
    showHorizontalIcons: boolean;
    veritalAreasAsTabs: boolean;
    orientation: SitemapOrientation;
};
export class Theme {
    private _logoUrl?: string;
    private _mainThemeV9: IFluentDesignState
    private _mainThemeV8: ITheme;
    private _ribbonThemeV9: IFluentDesignState;
    private _ribbonThemeV8: ITheme;
    private _navbarThemeV9: IFluentDesignState;
    private _navbarThemeV8: ITheme;
    private _navigationThemeV9: IFluentDesignState;
    private _formHeaderThemeV9: IFluentDesignState;
    private _formHeaderThemeV8: ITheme;
    private _navigationThemeV8: ITheme;
    public bodyBackgroudColor?: string;
    public gradientBackground: boolean;
    public containerWidth: number | undefined;
    public globalNotificationCollapseCount: number;
    public sitemap: ISitemapParameters;

    constructor(entity?: ComponentFramework.WebApi.Entity) {
        this.globalNotificationCollapseCount = entity?.["talxis_globalnotification_collapsecount"] ?? 2;
        this.containerWidth = entity?.["talxis_bodywidth"];
        this._injectCustomCss(entity);
        this._setSitemapParameters(entity);
        this._setThemeParameters(entity);
        this._setLogoUrl(entity);
        this._injectFavicon(entity);
        this._injectCSSVariables();
        loadTheme(this._mainThemeV8);
    }
    public get ribbon(): ITheme {
        return this._ribbonThemeV8;
    }
    public get navigation(): ITheme {
        return this._navigationThemeV8;
    }
    public get navbar() {
        return this._navbarThemeV8;
    }
    public get main() {
        return this._mainThemeV8;
    }
    public get mainV9() {
        return this._mainThemeV9;
    }
    public get navbarV9() {
        return this._navbarThemeV9;
    }
    public get formHeader() {
        return this._formHeaderThemeV8;
    }
    public get formHeaderV9() {
        return this._formHeaderThemeV9;
    }
    public get logoUrl() {
        return this._logoUrl;
    }

    private _setLogoUrl(entity: any) {
        if (entity?.['talxis_logotooltip']) {
            this._logoUrl = buildMetadataUrl(`v9.1/talxis_configuration_themes(${entity['talxis_configuration_themeid']})/talxis_logotooltip/$value`, false, isDev());
            return;
        }
        this._logoUrl = this._navbarThemeV8.isInverted ? `images/talxis_logo_white.png` : `images/talxis_logo_black.png`;
    }

    private _injectCSSVariables() {
        const variables: {
            [name: string]: string;
        } = {};
        const injectVaribles = (name: ThemeType, theme: ITheme) => {
            for (const [key, value] of Object.entries(theme.semanticColors)) {
                variables[`--talxis-${name}-${key}`] = value;
            }
            for (const [key, value] of Object.entries(theme.palette)) {
                variables[`--talxis-${name}-${key}`] = value;
            }
        };
        injectVaribles(ThemeType.MAIN, this._mainThemeV8);
        injectVaribles(ThemeType.NAVBAR, this._navbarThemeV8);
        injectVaribles(ThemeType.RIBBON, this._ribbonThemeV8);
        injectVaribles(ThemeType.NAVIGATION, this._navigationThemeV8);
        document.body.classList.add(mergeStyleSets({
            themeVariables: {
                '--talxis-main-bodyBackgroundMain': this.bodyBackgroudColor,
                ...(this.containerWidth && { '--talxis-main-bodyWidth': `${this.containerWidth}px` }),
                ...variables
            }
        }).themeVariables);
    };

    private _injectCustomCss(entity: any) {
        const customCSS = entity?.["talxis_cascadingstylesheets"];
        if (!customCSS) {
            return;
        }
        const styleElement = document.createElement('style');
        styleElement.setAttribute('type', 'text/css');
        styleElement.setAttribute('id', 'customCSS');
        styleElement.innerHTML = this._replaceWebResourceLinksInCss(customCSS);
        document.head.appendChild(styleElement);
    }

    private _replaceWebResourceLinksInCss(customCSS: string) {
        const generatedCss = customCSS.replace(/: url\(['"]?\/webresources\/(.*)['"]?\)/i, `: url(${getWebResourceUrl(`webresources/$1`)})`);
        return generatedCss;
    }
    private _generateTheme(primaryColor: string, backgroundColor: string, textColor: string, options?: {
        overrides?: {
            inputBackground?: string;
            inputBorder?: string;
            inputBorderHovered?: string;
            inputText?: string;
            inputPlaceholderText?: string;
            primaryColor?: string;
            underlined?: boolean
        },
        mainTheme?: ITheme
    }): [IFluentDesignState, ITheme] {
        const v8Theme = Theming.GenerateThemeV8(primaryColor, backgroundColor, textColor);
        const v9Theme = ControlTheme.GenerateFluentDesignLanguage(primaryColor, backgroundColor, textColor, {
            applicationTheme: options.mainTheme ?? v8Theme,
            v8FluentOverrides: {
                effects: {
                    underlined: options?.overrides?.underlined ?? true
                },
                semanticColors: (() => {
                    const result = {};
                    Object.entries(options?.overrides ?? {}).map(([key, value]) => {
                        //@ts-ignore - typings
                        if (v8Theme.semanticColors[key] && value) {
                            //@ts-ignore - typings
                            result[key] = value;
                        }
                    });
                    return result;
                })()
            }
        });
        return [v9Theme, v8Theme];
    }

    private _injectFavicon(entity: any) {
        let faviconUrl = `images/favicon.ico?v=${FRONTEND_VERSION}`;
        if (entity?.['talxis_faviconlogoid']) {
            const url = new URL(buildMetadataUrl(`v9.1/talxis_configuration_themes(${entity['talxis_configuration_themeid']})/talxis_faviconlogoid/$value`, false, isDev()));
            //&talxisFavicon parameter is used so the url does not end with .com since it breaks icon fetching
            url.searchParams.append('talxis_favicon', 'true');
            faviconUrl = url.toString();
        }
        const faviconElement = document.createElement('link');
        faviconElement.setAttribute('rel', 'icon');
        faviconElement.setAttribute('href', faviconUrl);
        document.head.appendChild(faviconElement);
    }

    private _setSitemapParameters(entity: any) {
        this.sitemap = {
            horizontalAlignment: entity?.["talxis_sitemapalignmenttypecode"] ?? SitemapAlignment.Center,
            orientation: entity?.["talxis_sitemaporientationtypecode"] ?? SitemapOrientation.Vertical,
            showHorizontalIcons: entity?.["talxis_showsitemapitemicons"] ?? false,
            veritalAreasAsTabs: entity?.["talxis_areasastabs"]
        };
    }

    private _setThemeParameters(entity: any) {
        //default value set to 2 to follow PowerApps design
        this.gradientBackground = entity?.["talxis_backgroundgradient"];
        //this color refers to the background color of entire page and isn't reflected on surfaces
        this.bodyBackgroudColor = entity?.['talxis_bodybackgroundcolor'] ?? DEFAULT_BODY_MAIN_BACKGROUND_COLOR;
        const baseTheme = getTheme();

        //this applies to the background color of all fluent surfaces (card, callout, panel...)
        //we currently only support white background since a lot of PCF's and portal code expects these surfaces to be white
        //changing this color will break a lot of things, thus why it is currently hardcoded to white
        [this._mainThemeV9, this._mainThemeV8] = this._generateTheme(entity?.["talxis_maincolor"] ?? baseTheme.palette.themePrimary, 'white', entity?.["talxis_textcolor"] ?? baseTheme.semanticColors.bodyText, {
            overrides: {
                inputBackground: entity?.["talxis_controlinputbackgroundcolor"],
                inputBorder: entity?.["talxis_controlinputbordercolor"],
                inputBorderHovered: entity?.["talxis_controlinputborderhoveredcolor"],
                inputPlaceholderText: entity?.["talxis_controlinputplaceholdertextcolor"],
                inputText: entity?.["talxis_controlinputtextcolor"],
                underlined: entity?.["talxis_controlinputunderlinedtypecode"]
            },
        });
        [this._navbarThemeV9, this._navbarThemeV8] = this._generateTheme(entity?.["talxis_navbarprimarycolor"] ?? this._mainThemeV8.palette.white, entity?.["talxis_navbarbackgroundcolor"] ?? this._mainThemeV8.palette.themePrimary, entity?.["talxis_navbartextcolor"] ?? this._mainThemeV8.palette.white, {
            mainTheme: this._mainThemeV8,
            overrides: {
                inputBackground: entity?.["talxis_navbarcontrolinputbackgroundcolor"],
                inputBorder: entity?.["talxis_navbarcontrolinputbordercolor"],
                inputBorderHovered: entity?.["talxis_navbarcontrolinputborderhoveredcolor"],
                inputPlaceholderText: entity?.["talxis_navbarcontrolinputplaceholdertextcolor"],
                inputText: entity?.["talxis_navbarcontrolinputtextcolor"],
                underlined: entity?.["talxis_navbarcontrolinputunderlinedtypecode"]
            }
        });
        [this._formHeaderThemeV9, this._formHeaderThemeV8] = this._generateTheme(this._mainThemeV8.palette.themePrimary, this._mainThemeV8.semanticColors.bodyBackground, this._mainThemeV8.semanticColors.bodyText, {
            mainTheme: this._mainThemeV8,
            overrides: {
                inputPlaceholderText: entity?.["talxis_controlinputplaceholdertextcolor"],
                inputText: entity?.["talxis_controlinputtextcolor"],
                primaryColor: entity?.["talxis_controlprimarycolor"],
                underlined: entity?.["talxis_controlunderlined"],
                inputBackground: this._mainThemeV8.semanticColors.bodyBackground,
                inputBorder: 'transparent',
                inputBorderHovered: 'transparent'
            }
        });
        [this._ribbonThemeV9, this._ribbonThemeV8] = this._generateTheme(entity?.["talxis_ribbonprimarycolor"] ?? this._mainThemeV8.palette.themePrimary, entity?.["talxis_ribbonbackgroundcolor"] ?? this._mainThemeV8.palette.neutralLighter, entity?.["talxis_ribbontextcolor"] ?? this._mainThemeV8.semanticColors.bodyText, {
            mainTheme: this._mainThemeV8
        });
        [this._navigationThemeV9, this._navigationThemeV8] = this._generateTheme(entity?.["talxis_navigationprimarycolor"] ?? this._mainThemeV8.palette.themePrimary, entity?.["talxis_navigationbackgroundcolor"] ?? this._mainThemeV8.palette.neutralLighter, entity?.["talxis_navigationtextcolor"] ?? this._mainThemeV8.semanticColors.bodyText, {
            mainTheme: this._mainThemeV8
        });
    }

}