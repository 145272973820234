import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { IconButton } from '@fluentui/react/lib/Button';
import { OverflowSet } from '@fluentui/react/lib/OverflowSet';
import { Text } from '@fluentui/react/lib/Text';
import { ThemeDefinition } from '@src/app/classes/definitions/ThemeDefinition';
import { BackButton } from '@src/components/navigation/buttons/BackButton';
import { EntityDefinition } from '@src/app/classes/definitions/EntityDefinition';
import { DomParser } from '@src/app/Constants';
import { getStyles } from './styles';
import { EntityDefinition as IEntityDefinition } from '@src/app/interfaces/entitydefinition';
import { _eInternalMessageId } from '@microsoft/applicationinsights-web';
import { AppModule } from '@src/app/classes/configuration/AppModule';
import { ViewDefinition } from '@src/app/classes/definitions/ViewDefinition';
import { IQuickFindView } from '@src/components/controls/native/View/interfaces/viewdefinition';
import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { List } from '@fluentui/react/lib/List';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { FontIcon, Icon } from '@fluentui/react/lib/Icon';
import { Link, mergeStyles } from '@fluentui/react';
import { LocalizeLabel } from '@src/localization/helpers';
import { PortalWebApiError, WebApiErrors } from '@src/ComponentFramework/PropertyClasses/WebApi';
import { IAppContext } from '@src/providers/AppProvider/interfaces';
import { AppContext } from '@providers/AppProvider';
import { HistoryContext } from '@src/providers/HistoryProvider/HistoryProvider';
import { Authentication, requireAuthentication } from '@src/app/classes/Authentication';
import { ComboBox } from '@talxis/react-components';

interface IResultList {
    entityLogicalName: string;
    entityDisplayName: string;
    error?: string;
    records: {
        recordId: string;
        primaryText?: string;
        secondaryText?: string;
    }[] | null;
}

export const errorList = mergeStyles({
    selectors: {
        '.TALXIS-Portal-searchError-icon': {
            position: 'relative',
            top: 4,
            marginRight: 5,
            color: "red",
            fontSize: 24
        }
    },
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
});

export const SearchPage: React.FC<RouteComponentProps> = (props) => {
    const appContext: IAppContext = useContext(AppContext);
    const historyContext = useContext(HistoryContext);

    const currentApp = AppModule.get();

    const theme = ThemeDefinition.get().main;
    const query = new URLSearchParams(useLocation().search);
    const searchText = query.get('searchText');

    const [filteredResultList, setFilteredResultList] = useState<IResultList[]>(null);

    const [page, setPage] = useState(1);
    const [resultsOnPageStyle, setResultsOnPageStyle] = useState(3);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedEntity, setSelectedEntity] = useState("none");
    const resultsOnPage = useRef(3);

    const searchResultsRef = useRef<IResultList[]>();
    const containerRef = useRef(null);

    const styles = React.useMemo(() => getStyles(theme, resultsOnPage.current), [resultsOnPageStyle]);

    useEffect(() => {
        // Search Page currently requires authentication
        if (!Authentication.isAuthenticated()) {
            requireAuthentication();
            return;
        }
        appContext.SetLoading(false);
        document.querySelector('head > title').innerHTML = `${AppModule.get().name} | ${window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/Search/Search")}`;
    }, [historyContext.currentPage]);

    useEffect(() => {
        const handleResize = (entries: ResizeObserverEntry[]) => {
            for (let entry of entries) {
                if (entry.target === containerRef.current) {
                    if (entry.contentRect.width < 670) {
                        if (resultsOnPage.current != 1) {
                            resultsOnPage.current = 1;
                            setResultsOnPageStyle(1);
                            setTotalPages(filteredResultList?.length ?? 1);
                        }
                    } else if (entry.contentRect.width < 980) {
                        if (resultsOnPage.current !== 2) {
                            resultsOnPage.current = 2;
                            setResultsOnPageStyle(2);
                            setTotalPages(Math.ceil(filteredResultList?.length / 2) ?? 1);
                            setPage(Math.ceil(page / 3));
                        }

                    } else {
                        if (resultsOnPage.current !== 3) {
                            resultsOnPage.current = 3;
                            setResultsOnPageStyle(3);
                            setTotalPages(Math.ceil(filteredResultList?.length / 3) ?? 1);
                            setPage(Math.ceil(page / 2));
                        }
                    }
                }
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, [filteredResultList, resultsOnPage.current]);

    useEffect(() => {
        if (searchText) {
            setSelectedEntity("none");
            const fetchData = async () => {
                Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('@controls/loadings/MainLoading'));

                const promiseFunctions: (() => Promise<IResultList>)[] = [];
                const entities: {
                    metadata: IEntityDefinition,
                    quickFindView?: IQuickFindView;
                }[] = [];

                for (const entity of currentApp.searchenabledentities) {
                    try {
                        const metadata = await EntityDefinition.getAsync(entity);

                        if (metadata.IsValidForAdvancedFind === true) {
                            entities.push({
                                metadata: metadata,
                                quickFindView: await ViewDefinition.getQuickFindViewAsync(entity)
                            });
                        }
                    } catch (error) {
                        Xrm.Utility.closeProgressIndicator();
                        throw new Error(`An error was ecountered while fetching metadata for entity ${entity}, ${error}`);
                    }
                }

                // Set initial number of avaliable entities which will await their results
                const availableEntities: IResultList[] = [];
                entities.map((entity) => {
                    availableEntities.push({
                        entityDisplayName: LocalizeLabel(entity.metadata.DisplayName.LocalizedLabels),
                        entityLogicalName: entity.metadata.LogicalName,
                        records: []
                    });
                });

                setFilteredResultList(availableEntities);
                setTotalPages(Math.ceil((availableEntities.length) / resultsOnPage.current));

                // Compose promise for all search enabled entities
                for (const entity of entities) {
                    promiseFunctions.push(() => new Promise<IResultList>(async (resolve) => {
                        if (entity.quickFindView) {
                            const fetchXml = DomParser.parseFromString(entity.quickFindView.fetchxml, "application/xml");
                            const quickFindFilter = fetchXml.querySelector(`filter[isquickfindfields="1"]`);
                            for (const condition of Array.from(quickFindFilter.querySelectorAll(":scope > condition"))) {
                                condition.setAttribute('value', `%${searchText}%`);
                            }

                            const serializer = new XMLSerializer();
                            try {
                                const result = (await Xrm.WebApi.retrieveMultipleRecords(entity.metadata.LogicalName, `?fetchXml=${encodeURIComponent(serializer.serializeToString(fetchXml))}&$top=20`)).entities;
                                if (result.length === 0) {
                                    resolve({
                                        entityDisplayName: LocalizeLabel(entity.metadata.DisplayName.LocalizedLabels),
                                        entityLogicalName: entity.metadata.LogicalName,
                                        records: null
                                    });
                                    return;
                                }
                                const viewLayout = entity.quickFindView.layoutjson ? JSON.parse(entity.quickFindView.layoutjson) : null;
                                resolve({
                                    entityDisplayName: LocalizeLabel(entity.metadata.DisplayName.LocalizedLabels),
                                    entityLogicalName: entity.metadata.LogicalName,
                                    records: result.map(x => {
                                        const primaryField = viewLayout?.Rows[0]?.Cells[0]?.Name;
                                        const secondaryField = viewLayout?.Rows[0]?.Cells[1]?.Name;

                                        let primaryText = x[entity.metadata.PrimaryNameAttribute];
                                        let secondaryText = '---';

                                        if (primaryField) {
                                            primaryText = x[`${primaryField}@OData.Community.Display.V1.FormattedValue`] ?? x[primaryField];
                                        }

                                        if (secondaryField) {
                                            secondaryText = x[`${secondaryField}@OData.Community.Display.V1.FormattedValue`] ?? x[secondaryField];
                                        }

                                        return {
                                            recordId: x[entity.metadata.PrimaryIdAttribute],
                                            primaryText: primaryText,
                                            secondaryText: secondaryText
                                        };
                                    })
                                });
                                return;
                            } catch (err: any) {
                                let errorMessage: string;
                                if (err?.name === "PortalWebApiError") {
                                    const exception = err as PortalWebApiError;
                                    errorMessage = `${exception.message}\n\n${exception.details}\n\n${exception.stack}`;
                                }
                                else if (Object.values(WebApiErrors).includes(err?.code)) {
                                    switch (err.code) {
                                        case WebApiErrors.QUICK_FIND_QUERY_RECORD_LIMIT_EXCEEDED:
                                            errorMessage = window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/Search/ErrorDetails/QuickFindQueryRecordLimitExceeded");
                                            break;
                                    }
                                }
                                else {
                                    errorMessage = JSON.stringify(err);
                                }
                                resolve({
                                    entityDisplayName: LocalizeLabel(entity.metadata.DisplayName.LocalizedLabels),
                                    entityLogicalName: entity.metadata.LogicalName,
                                    records: null,
                                    error: errorMessage
                                });
                                return;
                            }
                        }
                        // TODO: This might result in an error if the entity does not have a quick find view
                        resolve({
                            entityDisplayName: LocalizeLabel(entity.metadata.DisplayName.LocalizedLabels),
                            entityLogicalName: entity.metadata.LogicalName,
                            records: null
                        });
                    }));
                }

                Xrm.Utility.closeProgressIndicator();

                // Fetching results and displaying them as they fetch
                const chunkSize = 3;
                for (let i = 0; i < promiseFunctions.length; i += chunkSize) {
                    const chunk = promiseFunctions.slice(i, i + chunkSize);
                    const chunkResults = await Promise.all(chunk.map(promise => promise()));

                    for (const chunkRes of chunkResults) {
                        const entity = availableEntities.find(x => x.entityLogicalName === chunkRes.entityLogicalName);
                        if (chunkRes.records?.length > 0) {
                            entity.records = chunkRes.records;
                        }
                        else if (chunkRes.error) {
                            entity.error = chunkRes.error;
                        }
                        else {
                            availableEntities.splice(availableEntities.indexOf(entity), 1);
                        }
                    }
                    searchResultsRef.current = availableEntities;
                    setFilteredResultList([...availableEntities]);
                    setTotalPages(Math.ceil((availableEntities.length) / resultsOnPage.current));
                }
            };
            fetchData();
        }
    }, [searchText]);

    const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
        return (
            <IconButton
                menuIconProps={{ iconName: 'More' }}
                menuProps={{ items: overflowItems! }}
            />
        );
    };

    const onItemInvoked = async (recordId: string, entityName: string) => {
        const entityFormOptions: Xrm.Navigation.EntityFormOptions = {
            entityName: entityName,
            entityId: recordId
        };
        await window.Xrm.Navigation.openForm(entityFormOptions);
    };

    const handleNext = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const getShimmerElements = (): JSX.Element => {
        return (
            <div style={{ display: "flex" }}>
                <ShimmerElementsGroup
                    shimmerElements={[
                        { type: ShimmerElementType.circle, height: 40 },
                        { type: ShimmerElementType.gap, width: 16, height: 40 },
                    ]}
                />
                <ShimmerElementsGroup
                    flexWrap
                    width="100%"
                    shimmerElements={[
                        { type: ShimmerElementType.line, width: '100%', height: 10, verticalAlign: 'bottom' },
                        { type: ShimmerElementType.line, width: '90%', height: 8 },
                        { type: ShimmerElementType.gap, width: '10%', height: 20 },
                    ]}
                />
            </div>
        );
    };

    const getAvailableEntities = useCallback(() => {
        return [
            { key: 'none', text: window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/Search/None") },
            ...searchResultsRef.current.map(result => { return { key: result.entityLogicalName, text: result.entityDisplayName }; })
        ];
    }, [filteredResultList]);

    return <div ref={containerRef} className={styles.root}>
        <OverflowSet
            className={styles.overflowset}
            items={[{ key: 'back', name: 'back' }]}
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={() => { return <BackButton />; }}
        />
        <div className={styles.header}>
            <div className={styles.searchResult}>
                {window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/Search/SearchResultsFor")}: {searchText}
            </div>
            {(searchResultsRef.current) &&
                <ComboBox
                    label={window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/Search/FilterWith")}
                    options={getAvailableEntities()}
                    selectedKey={selectedEntity}
                    onChange={(event, option, context) => {
                        if (option) {
                            setSelectedEntity(option.key as string);
                            const results = option.key === 'none' ? searchResultsRef.current : [searchResultsRef.current.find(result => result.entityLogicalName === option.key)];
                            setFilteredResultList(results);
                            setTotalPages(Math.ceil((results.length) / resultsOnPage.current));
                            setPage(1);
                        }
                    }}
                    styles={{
                        root: { width: `${resultsOnPage.current === 1 ? '100%' : '250px'}` }
                    }}
                />}
        </div>
        <div className={styles.body}>
            <div className={styles.lists}>
                {(filteredResultList && filteredResultList.length !== 0) &&
                    filteredResultList.slice((page - 1) * resultsOnPage.current, page * resultsOnPage.current).map(result => {
                        return (result.records !== null || result.error) &&
                            <div key={result.entityLogicalName} className={styles.listWrapper}>
                                {(result.records?.length === 0 && !result.error) &&
                                    <>
                                        <Shimmer className={styles.listHeader} width="40%" />
                                        <div className={styles.list}>
                                            {[...Array(10)].map((x, i) => {
                                                return (
                                                    <Shimmer
                                                        key={i}
                                                        styles={{
                                                            root: {
                                                                padding: 10,
                                                                background: theme.semanticColors.listBackground,
                                                            }
                                                        }}
                                                        customElementsGroup={getShimmerElements()}
                                                    />);
                                            })}
                                        </div>
                                    </>}
                                {result.records?.length !== 0 &&
                                    <>
                                        <div className={styles.listHeader}>
                                            {result.entityDisplayName}
                                        </div>
                                        <List
                                            className={styles.list}
                                            items={result.records}
                                            onRenderCell={(item: { recordId: string; primaryText?: string; secondaryText?: string }) => {
                                                return (
                                                    <Persona
                                                        key={item.recordId}
                                                        className={styles.persona}
                                                        text={item.primaryText}
                                                        secondaryText={item.secondaryText}
                                                        size={PersonaSize.size40}
                                                        onClick={() => onItemInvoked(item.recordId, result.entityLogicalName)} />
                                                );
                                            }}
                                        />
                                    </>}
                                {result.error &&
                                    <>
                                        <div className={styles.listHeader}>
                                            {result.entityDisplayName}
                                        </div>
                                        <List
                                            className={`${styles.list} ${errorList}`}
                                            onRenderSurface={(props) => {
                                                return <div>
                                                    <FontIcon iconName='ErrorBadge' className='TALXIS-Portal-searchError-icon' />
                                                    <br />
                                                    <Text>{window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/Search/Failed")} <Link onClick={() => {
                                                        Xrm.Navigation.openErrorDialog({
                                                            message: window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/Search/ErrorDetails"),
                                                            details: result.error
                                                        });
                                                    }}>{window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/Search/Here")}</Link>.</Text>
                                                </div>;
                                            }}
                                        />
                                    </>
                                }
                            </div>;
                    })
                }
                {
                    (filteredResultList && filteredResultList.length == 0) &&
                    <div className={styles.norecords}>
                        <Icon iconName="SearchAndApps" className={styles.noresulticon} />
                        {window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/Search/NoRecordsFound")}
                    </div>
                }
            </div>
            {
                (filteredResultList && filteredResultList.length > resultsOnPage.current) &&
                <div className={`${styles.pagination} ${filteredResultList.length <= resultsOnPage.current ? styles.invisible : ''}`}>
                    <IconButton className={`${page === 1 ? styles.invisible : ''}`} iconProps={{ iconName: 'ChevronLeftSmall' }} title="Previous" onClick={handlePrevious} />
                    <div>
                        {page} {window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/Search/Of")} {totalPages}
                    </div>
                    <IconButton className={`${(page === totalPages || filteredResultList.length <= page * resultsOnPage.current) ? styles.invisible : ''}`}
                        iconProps={{ iconName: 'ChevronRightSmall' }} title="Next" onClick={handleNext} />
                </div>
            }
        </div>
    </div>;
}; 